/* on smallest screens, display as flex column */

body {
  min-width: 300px;
}

.ss-main {
  margin-top: 100px;
}

.brand {
  font-size: 2em;
}

.summ-champ {
  width: 55px;
  height: auto;
  border-radius: 8px;
}

.summ-champ-big {
  width: 70px;
  height: auto;
  border-radius: 8px;
}

.inline-gap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.player-tiny {
  width: 20px;
  height: auto;
  border-radius: 50%;
  margin-right: 6px;
  align-self: center;
}

.player-tiny-text {
  margin-bottom: 5px;
  font-size: 0.75em;
  line-height: 1em;
  padding-top: 2px;
}

.show-more {
  margin-bottom: 1em;
}

#bar {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.small-margin {
  margin-bottom: 5px;
  margin-top: -5px;
}

.bg-medium {
  background-color: #bababa;
}

.med-margin {
  margin-bottom: 20px;
}

.indent {
  margin-left: 35px;
}

.indent-s {
  margin-left: 10px;
}

.indent-right {
  margin-right: 35px;
}

/* General */

.ss-title {
  font-size: 2rem;
  font-weight: 100;
}

.tiny-text {
  font-size: 0.8em;
  line-height: 0.95em;
}

.legal {
  position: absolute;
  left: -10000px;
}

.title {
  font-size: 2rem;
  font-weight: 100;
}

.brand {
  font-size: 2em;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

/* Match info styling */

.game-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.ss-game {
  min-width: 300px;
  display: grid;
  grid-template-areas: "tagline tagline" "champ stats" "champ  build" "teams teams";
  grid-template-columns: 1fr 3fr;
  max-width: 300px;
}

.ss-game-tagline {
  grid-area: tagline;
}

.ss-summoner {
  grid-area: summ;
}

.ss-content {
  grid-area: content;
}

.ss-game-champ {
  grid-area: champ;
  min-width: 80px;
  overflow: hidden;
  max-width: 100px;
  max-height: 250px;
  border-radius: 8px;
}

.ss-game-build {
  grid-area: build;
  display: grid;
  grid-template-areas: "build-summs build-vision" "build-items build-items";
  grid-template-columns: 1.5fr 1fr;
  width: 80%;
  padding-left: 20px;
  margin-top: -20px;
}

.ss-build-table {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: flex-start;
  grid-gap: 4px;
  padding: 0.2em;
  max-width: 120px;
}

.ss-game-stats {
  grid-area: stats;
  padding-left: 20px;
}

.ss-game-teams {
  grid-area: teams;
  position: absolute;
  left: -999px;
  white-space: nowrap;
}

.max-width {
  width: 100%;
}

.champ-slice {
  width: 100px;
  height: 350px;
  margin: -20px 0px -40px 0px;
  object-fit: cover;
  object-position: 60% 0%;
}

#champ {
  overflow: hidden;
  grid-area: image;
  max-width: 80px;
  max-height: 250px;
  border-radius: 8px;
}

/* Item build in game tab */

.ss-build-items {
  grid-area: build-items;
}

.ss-build-summs {
  grid-area: build-summs;
}

.ss-build-vision {
  position: absolute;
  left: -999px;
  grid-area: build-vision;
  padding-top: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ss-item {
  width: 2rem;
  height: 2rem;
}

.ward {
  width: 20px;
  height: auto;
  align-self: flex-start;
  margin-right: 6px;
  border-radius: 50%;
  padding-top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

@media (min-width: 460px) {
  .ss-game {
    /* Reorganize the game grid to show full details*/
    display: grid;
    grid-template-areas: "tagline tagline tagline" "champ build stats" "champ build teams";
    grid-template-columns: 20% 30% 50%;
    max-width: none;
  }
  .ss-game-build {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ss-game-teams {
    position: relative;
    left: 0px;
  }
  .ss-build-vision {
    position: relative;
    left: 0px;
  }
  .ss-game-stats {
    text-align: center;
    padding-left: 0;
  }
  .ss-build-table {
    margin-bottom: 8px;
  }
}

/* Main styling changes */

@media (min-width: 820px) {
  /* partial split */
  .ss-content {
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-areas: "games stats";
  }
}

@media (min-width: 1280px) {
  .ss-main {
    display: grid;
    grid-template-areas: "summ content";
    grid-template-columns: 1fr 2fr;
  }
  .ss-content {
    display: grid;
    grid-template-areas: "games stats";
    grid-template-columns: 1fr 1fr;
  }
}

.ss-recent-stats {
  position: relative;
  grid-area: stats;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ss-recent-games {
  grid-area: games;
}

.ss-recent-champs {
  position: fixed;
  left: -999px;
}

/* Left sidebar (The same as the original!) */

.ss-subtitle {
  font-size: 1.4em;
  font-weight: 100;
}

.ss-mastery-title {
  text-align: center;
  grid-area: title;
}

.ss-summoner-container {
}

.ss-masteries {
  justify-self: center;
  align-self: center;
  grid-area: mastery;
}

.ss-summoner-grid {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-areas: "name" "ranked" "mastery";
}

.ss-name-level {
  grid-area: name;
}

.ss-mastery-detail {
  position: absolute;
  left: -999px;
}

.ss-ranked {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  grid-area: ranked;
}

.ss-rank-text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 6px;
  margin-right: 6px;
}

.ss-rank-i {
  min-width: 65px;
  width: 22%;
  height: auto;
  margin-left: 10px;
}

.ss-mastery {
  grid-area: mastery;
  margin: 20px 5px 20px 5px;
}

@media (min-width: 500px) {
  /* partial split */
  .ss-mastery-detail {
    position: relative;
    left: 0px;
  }
}

@media (min-width: 820px) {
  /* partial split */
  .ss-summoner-grid {
    grid-template-areas: "name ranked" "mastery mastery";
    grid-template-columns: 1fr 1fr;
  }
  .ss-mastery-items {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1280px) {
  .ss-summoner-grid {
    grid-template-columns: 1fr;
    grid-template-areas: "name" "ranked" "mastery";
  }
  .ss-ranked {
    flex-direction: row;
  }
  .ss-rank-text {
    align-items: flex-end;
  }
  .ss-mastery-items {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ss-summoner-grid {
    display: grid;
    justify-items: end;
    align-items: end;
    grid-template-areas: "name" "ranked" "mastery";
  }
  .ss-ranked {
    justify-content: end;
  }
  .ss-mastery-title {
    text-align: right;
  }
  .ss-mastery-items {
    align-items: end;
  }
}

/* Right sidebar */

.ss-right-container {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 90%;
}

.ss-right-container-box {
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.tiny-resize {
  font-size: 12px;
}

@media (min-width: 400px) {
  .tiny-resize {
    font-size: 17px;
  }
}

@media (min-width: 820px) {
  /* partial split */
  .ss-content {
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-areas: "games stats";
  }
  .ss-recent-champs {
    position: relative;
    left: 0px;
  }
  .ss-right-container-box {
    align-items: flex-start;
  }
  .ss-recent-stats {
    justify-content: flex-start;
  }
  .legal {
    position: relative;
    left: 0px;
  }
  .legal-alt {
    position: absolute;
    left: -10000px;
  }
}

/* Must change styling to go from vertical horizontal to vertical! */

/* Navbar */

@media (min-width: 530px) {
  .ss-main {
    margin-top: 75px;
  }
}
